.sidebar-closed {
  background-color: #eee;
  transition: all 0.2s;
  min-width: 3em;
  align-items: center;
}

.sidebar-closed:hover {
  background: #ddd;
}

.sidebar-open {
  background-color: #eee;
  min-width: 15rem;
}
