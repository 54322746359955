.formrow {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 1em;
  background-color: #f8f8f8;
  padding: 0.5em;
  border-radius: 0.3em;
  border: solid 1px #eee;
}

.formlabel {
  flex: 0 0 auto;
  width: 15%;
  font-size: 1rem;
  margin: 0px!important;
  font-weight: bold;
}
